import React from "react";
import { Modal } from "react-bootstrap";

function VideoDisplayModal({ showModal, handleClose, currentVideo }) {
  return (
    <Modal show={showModal} centered>
      <Modal.Body style={{ padding: 0 }}>
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%", // 16:9 aspect ratio (height/width) 
            height: 0,
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={currentVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <button
            style={{
              backgroundColor: "rgb(239 239 239)",
              border: "none",
              borderRadius: "50%",
              boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px",
              color: "rgb(0 0 0)",
              fontSize: "16px",
              height: "23px",
              margin: "0px",
              opacity: "1",
              outline: "0px",
              padding: "0px",
              position: "absolute",
              right: "-10px",
              top: "-10px",
              width: "23px",
            }}
            onClick={handleClose}
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default VideoDisplayModal;
