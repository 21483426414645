module.exports = {
  ApiUrl: "https://prithvi.initstore.com/api/",
  ArticleImgUrl:
    "https://prithvi.initstore.com/assets/images/PrinciplesArticle/",
  WebinarImgUrl: "https://prithvi.initstore.com/assets/images/Webinar/",
  DrugImgUrl: "https://prithvi.initstore.com/assets/images/DrugItem/",
  EbookImgUrl: "https://prithvi.initstore.com/assets/images/ThumbImage/",
  EbookPdfUrl: "https://prithvi.initstore.com/assets/images/Pdf/",
  VideoThumbUrl: "https://prithvi.initstore.com/assets/images/ThumbImage/",
  VideoUrl: "https://prithvi.initstore.com/assets/Videos/ArticleVideos/",
  shortsUrl: `https://prithvi.initstore.com/assets/Videos/ShortVideos/`,
  AudioUrl: `https://prithvi.initstore.com/assets/Audio/`,
  GuruImageUrl: "https://prithvi.initstore.com/assets/images/Askguru/",
  sessionKey: "authToken",
};

// module.exports = {
//     ApiUrl: 'http://localhost:6263/api/',
//     ArticleImgUrl: 'http://localhost:6263/assets/images/PrinciplesArticle/',
//     WebinarImgUrl: 'http://localhost:6263/assets/images/Webinar/',
//     DrugImgUrl: 'http://localhost:6263/assets/images/DrugItem/',
//     EbookImgUrl: 'http://localhost:6263/assets/images/ThumbImage/',
//     EbookPdfUrl: 'http://localhost:6263/assets/images/Pdf/',
//     VideoThumbUrl: 'http://localhost:6263/assets/images/ThumbImage/',
//     VideoUrl: 'http://localhost:6263/assets/Videos/ArticleVideos/',
//     shortsUrl: `http://localhost:6263/assets/Videos/ShortVideos/`,
//     AudioUrl: `http://localhost:6263/assets/Audio/`,
//     sessionKey: 'authToken'
// };

// module.exports = {
// 	ApiUrl: 'https://prithviuser.initstore.com/api/',
// 	ArticleImgUrl: 'https://prithviuser.initstore.com/assets/images/PrinciplesArticle/',
// 	WebinarImgUrl: 'https://prithviuser.initstore.com/assets/images/Webinar/',
// 	DrugImgUrl: 'https://prithviuser.initstore.com/assets/images/DrugItem/',
// 	EbookImgUrl: 'https://prithviuser.initstore.com/assets/images/ThumbImage/',
// 	EbookPdfUrl: 'https://prithviuser.initstore.com/assets/images/Pdf/',
// 	VideoThumbUrl: 'https://prithviuser.initstore.com/assets/images/ThumbImage/',
// 	VideoUrl: 'https://prithviuser.initstore.com/assets/Videos/ArticleVideos/',
// 	shortsUrl: `https://prithviuser.initstore.com/assets/Videos/ShortVideos/`,
// 	AudioUrl: `https://prithviuser.initstore.com/assets/Audio/`,
// 	sessionKey: 'authToken',
// }

// api url for live production build.
// https://admin.theprithvi.in/api
module.exports = {
  ApiUrl: "https://admin.theprithvi.in/api/",
  ArticleImgUrl: "https://admin.theprithvi.in/assets/images/PrinciplesArticle/",
  WebinarImgUrl: "https://admin.theprithvi.in/assets/images/Webinar/",
  DrugImgUrl: "https://admin.theprithvi.in/assets/images/DrugItem/",
  EbookImgUrl: "https://admin.theprithvi.in/assets/images/ThumbImage/",
  EbookPdfUrl: "https://admin.theprithvi.in/assets/images/Pdf/",
  VideoThumbUrl: "https://admin.theprithvi.in/assets/images/ThumbImage/",
  VideoUrl: "https://admin.theprithvi.in/assets/Videos/ArticleVideos/",
  shortsUrl: `https://admin.theprithvi.in/assets/Videos/ShortVideos/`,
  AudioUrl: `https://admin.theprithvi.in/assets/Audio/`,
  GuruImageUrl: "https://admin.theprithvi.in/assets/images/Askguru/",
  sessionKey: "authToken",
};
