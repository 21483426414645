import React from "react";
import { Modal } from "react-bootstrap";
import { AudioUrl } from "../../Constants/Config";
function AudioPlayModal({ showModal, handleCloseModal, AudioData }) {
  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            backgroundColor: "rgb(239 239 239)",
            border: "none",
            borderRadius: "50%",
            boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px",
            color: "rgb(0 0 0)",
            fontSize: "16px",
            height: "23px",
            margin: "0px",
            opacity: "1",
            outline: "0px",
            padding: "0px",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            width: "23px",
          }}
          onClick={handleCloseModal}
        >
          <i className="fas fa-times" ></i>
        </button>
        {AudioData && (
          <audio
            src={AudioUrl + AudioData.AudioSecretKey}
            controls
            autoPlay
            controlsList="nodownload"
            style={{ width: "100%" }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AudioPlayModal;
